.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

* {
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100vh;
  width: 100vw;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Open Sans", "PingFang SC", "Microsoft YaHei", "Helvetica Neue",
    "Hiragino Sans GB", "WenQuanYi Micro Hei", Arial, sans-serif;
  overscroll-behavior: none;
}

@keyframes move {
  0% {
    /* background-position: 0 0; */
    filter: hue-rotate(0deg) grayscale(10%) brightness(2.05);
  }
  25% {
    /* background-position: 50% 0; */
    filter: hue-rotate(180deg) grayscale(20%) brightness(2.25);
  }
  50% {
    /* background-position: 100% 0; */
    filter: hue-rotate(360deg) grayscale(30%) brightness(2.55);
  }
  75% {
    /* background-position: 50% 0; */
    filter: hue-rotate(180deg) grayscale(20%) brightness(2.25);
  }
  100% {
    /* background-position: 0 0; */
    filter: hue-rotate(0deg) grayscale(10%) brightness(2.05);
  }
}

.bg {
  background: url("./theBackground.png");
  background-size: cover;
  position: fixed;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  filter: blur(5px);
  filter: grayscale(30%);
  z-index: -1;
}

.slogan {
  color: white;
  margin-top: 24px;
  font-size: 36px;
  font-weight: 400;
}

.mask {
  width: 100vw;
  height: 65vh;
  animation: move 10s infinite;
  background-image: url("./theBackground.png");
  background-size: 100vw;
  justify-content: center;
  align-items: center;
  text-align: center;
  mask: url("./CHRISMCGOWAN.svg");
  mask-size: 100vw;
  mask-repeat: no-repeat;

  /* filter: grayscale(80%); */
  /* filter: brightness(2.55); */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
